export enum LandingTagColor {
  Red = 'red',
  Orange = 'orange',
  Yellow = 'yellow',
  LightGreen = 'lightGreen',
  DarkGreen = 'darkGreen',
  Green = 'green',
  Blue = 'blue',
  Navy = 'navy',
  Purple = 'purple',
  Pink = 'pink',
  Gray = 'gray',
  DarkGray = 'darkGray',
}
